body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.DottedBox {
    margin: 40px;
    border: 5px dotted pink;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.border {
    border: 2px solid #eee;
}

.mr {
    margin: 10px;
}

.ms-ChoiceField {
    padding: 10px;
}

.ba-Sidebar {
    position: fixed;
}

.ms-CardItem {
    width: 95%;
}

#navRequest .ms-Nav-navItem {
    padding-left: 7px;
}

#navRequest .ms-Nav-group {
    margin-top: 20px;
}

#myaccess-req .ms-ExpandingCard-compactCard {
    height: 80px;
}

#myaccess-req .ms-ExpandingCard-expandedCard {
    height: 300px;
}

#myacces-req-scope .ms-DetailsHeader {
    padding-top: 0px;
}

.searchInput {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 400;
    box-shadow: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 1px;
    padding-right: 0px;
    padding-bottom: 1px;
    padding-left: 4px;
    box-sizing: border-box;
    color: rgb(23, 23, 23);
    background-color: rgb(146, 207, 250);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    border-radius: 2px;
    border-width: 0px;
    border-color: rgb(177, 177, 177);
    height: 32px;
}

    .searchInput:hover {
        background-color: rgb(255, 255, 255);
        border-color: rgb(23, 23, 23);
    }

.IconFDA {
    top: 30% !important;
    right: 0px !important;
    position: fixed !important;
    z-index: 1000;
    float: right;
    width: 20px;
    height: 20px;
    color: white;
    background: rgb(0, 120, 212);
    font-size: smaller;
}

.DivFDA {
    top: 50% !important;
    right: -50px !important;
    position: fixed !important;
    z-index: 1000;
    width: 0;
}
#obf-OverlayBackground {
    background-color: rgba(0, 0, 0, 0.5);
    color: #444444;
    left: 0;
    top: 0;
    width: 100%;
    height: 110%;
    overflow-y: auto;
    min-height: 220px;
    position: absolute;
    z-index: 10000000000;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 780px;
}
.FDAAnchor {
    float: right;
    vertical-align: top;
    transform: rotate(7deg);
    -ms-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    width: 120px !important;
    height: 30px !important;
    text-decoration: none !important;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}

.FDASpan {
    font-size: 18px;
    font-family: "Segoe UI";
    text-decoration: none;
    color: #fff;
    position: relative;
    justify-content: center;
    display: flex;
}

#tbl-overview table, #tbl-overview th, #tbl-overview td {
    border: 1px solid #666;
}

#tbl-overview table th, #tbl-overview table td {
        padding: 10px;
    }

    #tbl-overview table {
    border: none;
    border-spacing: 15px;
}

#tbl-overview tr {
    vertical-align: top;
}

h1 {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(23, 23, 23);
    box-sizing: border-box;
    box-shadow: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    display: block;
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
    padding-left: 0px;
    overflow-wrap: break-word;
}

h2 {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(23, 23, 23);
    box-sizing: border-box;
    box-shadow: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    display: block;
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
    padding-left: 0px;
    overflow-wrap: break-word;
}

@media screen and (-ms-high-contrast: active) {
    .ms-Link:focus {
        border: 1px solid transparent;
        outline: none;
    }
} 

.searchIconBackgroud:hover {
    background-color: rgb(0, 80, 169) !important;
}

#admin-user-mgmt-grid .ms-DetailsRow-cell {
    font-size: 14px;
    color: #171717;
}

.companyTable,.tb-asset-detail,.tb-reg-req-detail {
    border-collapse: collapse;
    width: 100%;
}

.companyTable,.tb-asset-detail,.tb-reg-req-detail td, .companyTable,.tb-asset-detail,.tb-reg-req-detail th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

    .companyTable,.tb-asset-detail,.tb-reg-req-detail th {
        background-color: rgb(245, 245, 245);
    }

    .tb-asset-detail,.tb-reg-req-detail tr {
        line-height: 15px;
    }

.tb-asset-detail td:first-child {
    width: 35%;
    border-right: 1px solid #dddddd;
    line-height: 20px;
}
.tb-reg-req-detail td:first-child {
    width: 35%;
}
#admin-tag-picker .ms-TagItem {
    max-width: none;
}

.obf-PrivacyStatementLinkDiv{
    display: none;
}

#attr-desc-tbl table, #attr-desc-tbl th, #attr-desc-tbl td {
    border: 1px solid #666;
    border-collapse: collapse;
}

#attr-desc-tbl table th, #attr-desc-tbl table td {
        padding: 3px;
    }

    #attr-desc-tbl table {
    border-spacing: 0 px;
    border-collapse: collapse;

}

.attr-desc-sec{
    font-size: 14px;
    font-weight: 400;
}
.ms-Persona{
    width: auto;
}

.ms-Dropdown-title {
    height: 38px;
    padding-top: 1px;
}
.ms-CommandBarItem-link .ms-Button-icon {
    color: rgb(255 255 255) !important;
}

#fdl-consumer-asset .ms-DetailsList {
    overflow-x: hidden !important;
}
#fdl-asset .ms-DetailsList {
    overflow-x: hidden !important;
}
#fdl-register-request .ms-DetailsList {
    overflow-x: hidden !important;
}

#admin-user-mgmt-grid .ms-DetailsList {
    /*overflow-x: hidden !important;*/
}

iframe{
    display: none !important;
}

.react-checkbox-tree label {
    color:#323130;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}
.side-nav-expand-collapse :hover {
    background-color: #cac6c2 !important
}
#Header :focus::after {
    outline: rgb(255, 255, 255) solid 1px !important;
}